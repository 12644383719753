import Layout from '../components/Layout';
import { Settings as SettingsComponent } from '../components/Settings';

export function Settings() {
  return (
    <Layout>
      <SettingsComponent />
    </Layout>
  );
}
