import { Header } from './Header';
import { Footer } from './Footer';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { getProducts } from '../../queries/getProducts.ts';
import { getToken } from '../../utils.ts';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FunctionComponent<Props> = ({ children }) => {
  const { data: products } = useQuery({
    queryKey: ['products'],
    queryFn: getProducts,
    enabled: !!getToken(),
  });

  return (
    <div>
      <Header products={products?.data} />
      <Container fluid className={'m-0 p-0'}>
        <Row className={'m-0 p-0'}>
          <div className={'content-container'}>
            <Row>{children}</Row>
          </div>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default Layout;
