import axios from 'axios';
import { getMissionsUrl, header, patchMissionsUrl } from './constants.ts';

export async function getMissions(territorySlug: string, queryParameters: Record<string, any>) {
  return await axios.get(getMissionsUrl(territorySlug, queryParameters), { headers: header });
}

export async function patchMissions(territorySlug: string, missionId: number, payload: object) {
  return await axios.patch(patchMissionsUrl(territorySlug, missionId), payload, { headers: header });
}
