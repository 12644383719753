import Layout from '../components/Layout';
import { Product as ProductComponent } from '../components/Product';

export function Product() {
  return (
    <Layout>
      <ProductComponent />
    </Layout>
  );
}
