import { useTranslation } from 'react-i18next';

export function Kpi() {
  const { t } = useTranslation();
  return (
    <div className={'top-center-container overflow-hidden d-flex align-items-center'}>
      <div className={'row h-100'}>
        <div className={'col'}>
          <p className={'ms-4 text-basic'}>{t('kpi.no-kpi')}</p>
        </div>
      </div>
    </div>
  );
}
