import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { getVehiclesAutomataLogs } from '../../../queries/getVehicles.ts';
import { Key, useState } from 'react';
import { FormatTime, getLogLevel } from '../../../utils.ts';
import { ILog } from '../../../interfaces/ILog.ts';
import { IVehicle } from '../../../interfaces/Vehicle.ts';

interface Props {
  territorySlug: string;
  vehicle: IVehicle;
}

export function Log(props: Props) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { data: AutomataLogsData } = useQuery({
    queryKey: ['automataLogs', props.territorySlug, props.vehicle?.id],
    queryFn: () =>
      props.vehicle?.id ? getVehiclesAutomataLogs(props.territorySlug, props.vehicle?.id) : null,
    enabled: props.vehicle?.id !== undefined,
    refetchInterval: 10000,
  });

  return (
    <>
      <Button className={'submit-button'} onClick={handleShow}>
        <p>
          <img src={'/theme/media/log.svg'} width="25" height="25" alt="Clock icon" />
          <span className={'ms-1 my-auto'}>Automata</span>
        </p>
      </Button>
      <Offcanvas className={'h-50'} show={show} placement={'bottom'} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className={'ms-2 card-subtitle'}>
            Automata - Vehicle {props.vehicle.display_identifier}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {AutomataLogsData?.status === 200 ? (
            AutomataLogsData?.data?.map((log: ILog, i: number) => (
              <div key={i}>
                <Row className={'mt-2'}>
                  <Col xs={1} className={'my-auto'}>
                    <p className={'text-basic ms-2'}>{FormatTime(log.timestamp)}</p>
                  </Col>
                  <Col xs={1} className={'my-auto'}>
                    <p>
                      <span className={'mission-lifecycle rounded-4'} style={getLogLevel(log.level)}>
                        {getLogLevel(log.level).name}
                      </span>
                    </p>
                  </Col>
                  <Col sm={5} lg={2} className={'my-auto'}>
                    <ul className={'m-0 p-0 ms-2'}>
                      {Object.keys(log.extra).map((key: string, index: Key) => (
                        <li className={'m-0 p-0'} key={index}>
                          <p className={'text-basic'}>
                            {key}: {log.extra[key]}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col sm={5} lg={8} className={'p-0 my-auto'}>
                    <p className={'text-basic'}>{log.message}</p>
                  </Col>
                </Row>
                <hr />
              </div>
            ))
          ) : (
            <></>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
