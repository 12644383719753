import { useMap } from 'react-leaflet';
import { useEffect } from 'react';

interface Props {
  lat: number;
  lng: number;
  zoom: number;
}

const RecenterMap = (props: Props) => {
  const map = useMap();
  useEffect(() => {
    map.setView([props.lat, props.lng], props.zoom);
  }, [map, props.lat, props.lng, props.zoom]);
  return null;
};

export default RecenterMap;
