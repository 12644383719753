import {
  Routes,
  Route,
  useLocation,
  useNavigationType,
  matchRoutes,
  createRoutesFromChildren,
} from 'react-router-dom';
import { Login } from './pages/Login.tsx';
import { Product } from './pages/Product.tsx';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './styles/style.css';
import './styles/login.css';
import './styles/layout.css';
import './styles/product.css';
import './styles/dashboard.css';
import './styles/mission-history.css';
import './styles/settings.css';
import { ProtectedRoute } from './components/Login/ProtectedRoute.tsx';
import { Dashboard } from './pages/Dashboard.tsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotFound } from './pages/NotFound.tsx';
import { SelectedVehicleContextProvider } from './contexts/SelectedVehicleContext.tsx';
import { ToastContainer } from 'react-toastify';
import { MissionHistory } from './pages/MissionHistory';
import * as Sentry from '@sentry/react';
import React from 'react';
import { SelectedNodeContextProvider } from './contexts/SelectedNodeContext.tsx';
import { Settings } from './pages/Settings.tsx';
import { ServerError } from './pages/ServerError.tsx';

const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://2537d2c2b9d7ff4e3cfd0ccd5e1c8953@sentry.tech.padam.io/19',
  environment: import.meta.env.VITE_ENV,
  enabled: import.meta.env.VITE_ENV !== 'LOCAL',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function App() {
  return (
    <>
      <SelectedVehicleContextProvider>
        <SelectedNodeContextProvider>
          <QueryClientProvider client={queryClient}>
            <SentryRoutes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Product />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/:territorySlug/"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mission-history/:territorySlug/"
                element={
                  <ProtectedRoute>
                    <MissionHistory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route path="/login/" element={<Login />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/server-error/" element={<ServerError />} />
            </SentryRoutes>
          </QueryClientProvider>
        </SelectedNodeContextProvider>
      </SelectedVehicleContextProvider>
      <ToastContainer />
    </>
  );
}

export default App;
