import { LoginForm } from './LoginForm.tsx';
import { useTranslation } from 'react-i18next';

export function Login() {
  const { t } = useTranslation();
  return (
    <div className="container vh-100">
      <div className="row align-items-center h-100">
        <div className="col-5 mx-auto">
          <div className="card login-card justify-content-center rounded-1 shadow">
            <img
              src={'/theme/media/logos/logo-default.svg'}
              height="100"
              className={'padam-logo'}
              alt="Padam mobility - AV - logo"
            />
            <h1 className={'sign-in text-center'}>{t('login.title')}</h1>
            <div className={'row d-flex justify-content-center mb-5'}>
              <div className={'col-10'}>
                <LoginForm />
              </div>
            </div>
          </div>
          <div className={'text-center padam-login-footer'}>
            <span className={'login-form-label'}>2024© </span>
            <a
              className={'padam-login-link'}
              href={'https://www.padam-mobility.com/en/'}
              target="_blank"
              rel="noreferrer"
            >
              Padam Mobility
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
