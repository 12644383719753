import axios from 'axios';
import { getPostActionUrl, header } from './constants.ts';
import { Action } from '../interfaces/Action.ts';

export async function postActions(territorySlug: string, vehicleId: number, payload: Action[]) {
  return await axios.post(
    getPostActionUrl(territorySlug, vehicleId),
    { actions: payload },
    {
      headers: header,
    },
  );
}
