import { MissionMessage, VehicleMessage } from '../interfaces/Message.ts';
import { IVehicle } from '../interfaces/Vehicle.ts';
import { IMission } from '../interfaces/IMission.ts';

export function processVehicleMessage(message: VehicleMessage, vehicles: IVehicle[]): IVehicle[] {
  const updatedVehicleIdentifier = message.provider_identifier;
  const vehiclesList = vehicles;
  for (let index = 0; index < vehiclesList.length; index++) {
    if (vehiclesList[index].provider_identifier === updatedVehicleIdentifier) {
      vehiclesList[index].charge_percentage = message.battery.level;
      message.door_status ? (vehiclesList[index].door_status = message.door_status) : null;
      vehiclesList[index].robot_mode = message.robot_mode;
      vehiclesList[index].supervision_mode = message.supervision_mode;
      vehiclesList[index].latitude = message.geo_position.lat;
      vehiclesList[index].longitude = message.geo_position.lon;
      break;
    }
  }
  return vehiclesList;
}

export function processMissionMessage(message: MissionMessage, missions: IMission[]): IMission[] {
  const updatedMissionIdentifier = message.provider_id;
  const missionList = missions;
  for (let index = 0; index < missionList.length; index++) {
    if (missionList[index].provider_identifier === updatedMissionIdentifier) {
      message.lifecycle ? (missionList[index].lifecycle = message.lifecycle) : null;
      message.progress ? (missionList[index].progress = message.progress.toString()) : null;
      message.estimated_distance
        ? (missionList[index].estimated_distance = message.estimated_distance)
        : null;
      message.estimated_duration
        ? (missionList[index].estimated_duration = message.estimated_duration)
        : null;
      message.remaining_distance
        ? (missionList[index].remaining_distance = message.remaining_distance)
        : null;
      message.remaining_duration
        ? (missionList[index].remaining_duration = message.remaining_duration)
        : null;
      break;
    }
  }
  return missionList;
}
