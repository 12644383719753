import axios from 'axios';
import {
  getCreateMissionUrl,
  getShiftUrl,
  getVehiclesAutomataLogsUrl,
  getVehiclesUrl,
  header,
  patchVehicleUrl,
} from './constants.ts';

export interface createMission {
  dispatch_destination_node_id: number;
  activity: number;
}

export async function getVehicles(territorySlug: string) {
  return await axios.get(getVehiclesUrl(territorySlug), { headers: header });
}

export async function getVehiclesAutomataLogs(territorySlug: string, vehicleId: number) {
  return await axios.get(getVehiclesAutomataLogsUrl(territorySlug, vehicleId), {
    headers: header,
  });
}

export async function getShift(territorySlug: string, vehicleId: number) {
  return await axios.get(getShiftUrl(territorySlug, vehicleId), {
    headers: header,
  });
}

export async function patchVehicle(
  territorySlug: string,
  vehicleId: number,
  payload: object,
) {
  return await axios.patch(patchVehicleUrl(territorySlug, vehicleId), payload, {
    headers: header,
  });
}

export async function postCreateMission(
  territorySlug: string,
  vehicleId: number,
  payload: createMission,
) {
  return await axios.post(
    getCreateMissionUrl(territorySlug, vehicleId),
    payload,
    { headers: header },
  );
}
