import {
    ACTION_NAME_PAUSE_SHIFT,
    ACTION_NAME_START_SHIFT, ACTION_NAME_UNPAUSE_SHIFT,
    SHIFT_STATUS_ONGOING,
    SHIFT_STATUS_PAUSED,
    SHIFT_STATUS_VALIDATE
} from "../../../constants.ts";
import {useContext} from "react";
import {SelectedVehicleContext} from "../../../contexts/SelectedVehicleContext.tsx";
import {Button, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {postActions} from "../../../queries/postActions.ts";
import {toast} from "react-toastify";
import {Action} from "../../../interfaces/Action.ts";

interface Props{
    territorySlug: string;
}

interface IShiftAction{
    successMessage: string
    errorMessage: string
    payload: Action
}

export function ShiftActions(props: Props){
    const { selectedVehicle } = useContext(SelectedVehicleContext);
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const mapping = {
        ACTION_NAME_START_SHIFT: {
            successMessage: t('roadmap.start-shift'),
            errorMessage: t('roadmap.start-shift-error'),
            payload: { name: ACTION_NAME_START_SHIFT, kwargs: {} }
        },
        ACTION_NAME_PAUSE_SHIFT: {
            successMessage: t('vehicle.pause-shift'),
            errorMessage: t('vehicle.pause-shift-error'),
            payload: { name: ACTION_NAME_PAUSE_SHIFT, kwargs: {} }
        },
        ACTION_NAME_UNPAUSE_SHIFT: {
            successMessage: t('vehicle.unpause-shift'),
            errorMessage: t('vehicle.unpause-shift-error'),
            payload: { name: ACTION_NAME_UNPAUSE_SHIFT, kwargs: {} },
        },
    }

    const mutation = useMutation({
        mutationFn: (payload: Action) => postActions(props.territorySlug, selectedVehicle?.vehicleId || 0, [payload]),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['shift', selectedVehicle?.vehicleId || 0]});
        }

    })

    function performShiftAction(shiftAction: IShiftAction){
        mutation.mutate(shiftAction.payload);
        if(mutation.isSuccess){
            toast.success(shiftAction.successMessage);
        } else if(mutation.error){
            toast.error(shiftAction.errorMessage);
        }
    }

    if(selectedVehicle?.shift?.status === SHIFT_STATUS_VALIDATE){
        return (
            <div className={'col-3 my-auto text-center'}>
                <Button onClick={() => performShiftAction(mapping.ACTION_NAME_START_SHIFT)} className={'primary-button'}>
                    {
                        mutation.isLoading ?
                        <span className={'d-flex justify-content-center'}>
                            <Spinner size={"sm"} animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <span className={'ms-1 my-auto'}>{t('roadmap.start-shift-button')}</span>
                        </span>
                        :
                        <span>
                            <img src={'/theme/media/unpause.svg'} width="25" height="25" alt="Start shift icon"/>
                            <span className={'ms-1 my-auto'}>{t('roadmap.start-shift-button')}</span>
                        </span>
                    }
                </Button>
            </div>
        )
    } else if (selectedVehicle?.shift?.status === SHIFT_STATUS_ONGOING) {
        return (
            <div className={'col-3 my-auto text-center'}>
                <Button onClick={() => performShiftAction(mapping.ACTION_NAME_PAUSE_SHIFT)} className={'alert-button'}>
                    {
                        mutation.isLoading ?
                        <span className={'d-flex justify-content-center'}>
                            <Spinner size={"sm"} animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <span className={'ms-1 my-auto'}>{t('vehicle.pause-shift')}</span>
                        </span>
                        :
                        <span>
                            <img src={'/theme/media/pause.svg'} width="25" height="25" alt="Pause icon"/>
                            <span className={'ms-1 my-auto'}>{t('vehicle.pause-shift')}</span>
                        </span>
                    }
                </Button>
            </div>
        )
    } else if (selectedVehicle?.shift?.status === SHIFT_STATUS_PAUSED) {
        return (
            <div className={'col-3 my-auto text-center'}>
                <Button onClick={() => performShiftAction(mapping.ACTION_NAME_UNPAUSE_SHIFT)} className={'primary-button'}>
                    {
                        mutation.isLoading ?
                        <span className={'d-flex justify-content-center'}>
                            <Spinner size={"sm"} animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <span className={'ms-1 my-auto'}>{t('vehicle.unpause-shift')}</span>
                        </span>
                        :
                        <span>
                            <img src={'/theme/media/unpause.svg'} width="25" height="25" alt="Un Pause icon"/>
                            <span className={'ms-1 my-auto'}>{t('vehicle.unpause-shift')}</span>
                        </span>
                    }
                </Button>
            </div>
        )
    }
}