import { Col, Nav, Navbar, Row } from 'react-bootstrap';
import { ChangePassword } from './ChangePassword.tsx';
import { useTranslation } from 'react-i18next';

export function Settings() {
  const { t } = useTranslation();
  return (
    <>
      <Col className={'settings-container'}>
        <Row>
          <Col xs={6}>
            <Navbar className={'settings-navbar border-bottom mb-2'}>
              <Nav activeKey={'/'} className="d-flex justify-content-center">
                <Nav.Link>
                  <p className={'settings-text-navbar'}>{t('settings.change-password')}</p>
                </Nav.Link>
              </Nav>
            </Navbar>
            <ChangePassword />
          </Col>
        </Row>
      </Col>
    </>
  );
}
