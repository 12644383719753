import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import en from './locales/en/translation.json';
import fr from './locales/fr/translation.json';

// https://recontent.app/blog/complete-guide-i18n-reactjs
export const defaultNamespace = 'default';

export const resources = {
  en: {
    [defaultNamespace]: en,
  },
  fr: {
    [defaultNamespace]: fr,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    defaultNS: defaultNamespace,
    ns: [defaultNamespace],
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
