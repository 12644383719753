import { useTranslation } from 'react-i18next';

export function Alert() {
  const { t } = useTranslation();
  return (
    <div className={'alert-container'}>
      <div className={'row m-0 p-0 pt-3 pb-3'}>
        <div>
          <p className={'card-title ms-2'}>
            <img
              src={'/theme/media/alert-icon.svg'}
              width="56"
              height="42"
              alt="Alert icon"
            />
            <span className={'ms-3'}>{t('alert.title')}</span>
          </p>
          <p className={'mt-2 ms-2 text-basic'}>{t('alert.no-alert')}</p>
        </div>
      </div>
    </div>
  );
}
