import { MissionHistory as MissionHistoryComponent } from '../components/MissionHistory';
import Layout from '../components/Layout';
import { useParams } from 'react-router-dom';
import { Suspense } from 'react';

export function MissionHistory() {
  const territorySlug = useParams() as { territorySlug: string };

  return (
    <Layout>
      <Suspense>
        <MissionHistoryComponent territorySlug={territorySlug.territorySlug} />
      </Suspense>
    </Layout>
  );
}
