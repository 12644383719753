import { TerritoryConfig } from '../../../interfaces/TerritoryConfig.ts';
import { createMission } from '../../../queries/getVehicles.ts';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { AUTOMATA_LEVEL_3, AUTOMATA_LEVEL_4 } from '../../../constants.ts';

export function getBatteryType(batteryPercentage: number): string {
  let batteryType;
  if (batteryPercentage > 75) {
    batteryType = '-full';
  } else if (25 <= batteryPercentage && batteryPercentage <= 75) {
    batteryType = '-half';
  } else {
    batteryType = '';
  }
  return batteryType;
}

export function getBatteryColor(batteryPercentage: number): string {
  let batteryColor;
  if (batteryPercentage > 75) {
    batteryColor = '-success';
  } else if (25 <= batteryPercentage && batteryPercentage <= 75) {
    batteryColor = '-warning';
  } else {
    batteryColor = '-danger';
  }
  return batteryColor;
}

export function doorStatusColor(doorStatus: string): string {
  let doorStatusColor;
  if (doorStatus === 'CLOSED' || doorStatus === 'OPENED') {
    doorStatusColor = 'success';
  } else {
    doorStatusColor = 'warning';
  }
  return doorStatusColor;
}

export function formatUpdatedAt(updatedAt: string): string {
  const updated_at_sec = new Date(updatedAt);
  return new Date(updated_at_sec).toLocaleString();
}

export function getMissionCreationPayload(
  event: { target: { elements: { nodesSelect: { value: never }; activitiesSelect: { value: never } } } },
  territoryConfig: TerritoryConfig | undefined | null,
): createMission {
  let payload = {
    dispatch_destination_node_id: event.target.elements.nodesSelect.value,
    activity: 3,
  };
  if (territoryConfig?.mission.activity_enabled) {
    payload = {
      dispatch_destination_node_id: event.target.elements.nodesSelect.value,
      activity: event.target.elements.activitiesSelect.value,
    };
  }
  return payload;
}

export function isAutomataDisabled(vehicle: IVehicle): boolean {
  return !vehicle.automata_enabled;
}
export function isLevel3Active(vehicle: IVehicle) {
  return vehicle.automata_level === AUTOMATA_LEVEL_3 && vehicle.automata_enabled;
}
export function isLevel4Active(vehicle: IVehicle) {
  return vehicle.automata_level === AUTOMATA_LEVEL_4 && vehicle.automata_enabled;
}
